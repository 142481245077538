.fabric {
  text-align: center;
  margin: 0 30px;
}
.wizard-container {
  display: inline-flex;
  width: 100%;
  text-align: left;
  max-width: 650px;
  margin-top: 5vw;
}
.logo {
  position: relative;
  left: calc(50% * -0.09);
  width: 50%;
}

.wizard {
  position: relative;
  width: 100%;
}
.wizard-step {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
.wizard-step-button-container {
  margin: 15px 0 !important;
}

.media-header {
  font-size: 28px;
}

.category {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  /* width: 120px;
  height: 120px; */
  width: calc(25% - 10px);
  height: 200px;
  margin: 5px;
  position: relative;
  text-align: center;
  border: solid 1px #eee;
  padding: 0 5px;
  overflow: hidden;
  outline: solid 3px transparent;
  transition: 0.3s;
  /* box-shadow: rgba(0, 0, 0, 0.129412) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.105882) 0px 0px 0px 0px; */
}
.categoryShimmer .ms-ShimmerLine-root {
  width: calc(25% - 10px) !important;
  height: 200px;
  border: solid 5px #fff;
}
.categoryShimmer .ms-ShimmerLine-root:nth-child(8) {
  background-color: #fff;
}
.categoryShimmer .ms-ShimmerLine-root:nth-child(9) {
  display: none;
}
.categoryShimmer .ms-ShimmerElementsGroup-root {
  flex-wrap: wrap !important;
}

.category:hover {
  outline-color: #5ab784;
}
.category.selected {
  outline-color: #148647;
}

.category img {
  /* position: absolute; */
  /* width: 50px; */
  width: 75px;
  margin-top: 35px;
  display: inline-block;
}
.category span {
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  padding: 0 20px;
}

.comboBoxShimmer {
  height: 32px;
}
.comboBoxShimmer > .ms-Shimmer-shimmerWrapper {
  height: 100%;
}

.recommendationShimmerContainer {
  margin-top: 20px;
  border: solid 1px #eee;
  padding: 12px;
}
.recommendationShimmer {
}

.recommendationContainer {
  margin-top: 20px;
  padding: 12px;
  border: solid 1px #eee;
}
.recommendationInnerContainer {
  display: flex;
}
.recommendationTextContainer {
  flex: 1;
}
.recommendationImageContainer {
  width: 250px;
  margin-left: 12px;
}
.recommendationImage {
  width: 100%;
}

.react-markdown p {
  margin-bottom: 0;
  margin-block-end: 0;
}

.user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 476px) {
  .fabric {
    margin: 0 6vw;
  }
}
@media (max-width: 700px) {
  .category {
    width: calc(33% - 10px);
  }
  .categoryShimmer .ms-ShimmerLine-root {
    width: calc(33.33% - 10px) !important;
  }
  .categoryShimmer .ms-ShimmerLine-root:nth-child(9) {
    display: inline;
    background-color: #fff;
  }
}
@media (max-width: 560px) {
  .media-header {
    font-size: 24px;
  }
  .category {
    width: calc(50% - 10px);
  }
  .categoryShimmer .ms-ShimmerLine-root {
    width: calc(50% - 10px) !important;
  }
  .categoryShimmer .ms-ShimmerLine-root:nth-child(9) {
    display: none;
  }

  .recommendationInnerContainer {
    display: block;
  }
  .recommendationTextContainer {
    display: block;
    flex: none;
  }
  .recommendationImageContainer {
    display: inl;
    width: 100%;
    margin-top: 12px;
    margin-left: 0px;
  }
}
@media (max-width: 425px) {
  .media-header {
    font-size: 22px;
  }
  .category {
    width: calc(100% - 10px);
  }
  .categoryShimmer .ms-ShimmerLine-root {
    width: 100% !important;
  }
  .categoryShimmer .ms-ShimmerLine-root:nth-child(8) {
    display: none;
  }
}

@media print {
  .wizard-container {
    margin-top: 10px;
    display: block;
  }
  .print-nobreak {
    page-break-inside: avoid;
  }

  .print-hidden {
    display: none;
  }

  .recommendationInnerContainer {
    display: flex;
  }
  .recommendationTextContainer {
    flex: 1;
  }
  .recommendationImageContainer {
    display: block;
    width: 250px;
    margin-top: 0;
    margin-left: 12px;
  }
  .recommendationImage {
    width: 100%;
  }
}
